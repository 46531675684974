var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.data.tooltip && _vm.data.tooltip !== "null") ||
    (_vm.data.txt && _vm.data.txt !== "null")
    ? _c("span", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: _vm.data.tooltip,
              hideOnTargetClick: false,
              trigger: _vm.innerWidth < 768 ? "click" : "hover",
            },
            expression:
              "{content: data.tooltip,hideOnTargetClick: false,trigger:innerWidth < 768?'click':'hover'}",
          },
        ],
        domProps: { textContent: _vm._s(_vm.data.txt) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }